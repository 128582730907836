import React from "react";
import ProdutoService from "./../../../services/Loja/ProdutoService";
import IResponse from "./../../../contract/IResponse";
import NotificationUtil from "./../../../utils/NotificationUtil";
import ClienteService from "./../../../services/ClienteService";
import PedidoService from "./../../../services/Loja/PedidoService";
import CookieUtil from "./../../../utils/CookieUtil";
import ModalUltil from "./../../../utils/ModalUtil";
import { updateQuantity } from "../../../actions/CartActions";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./ProdutosHome.scss";

import Gemini from '../../../assets/img/products/produtos/LOGO-GEMINI.png';
import UniDANFE from '../../../assets/img/products/produtos/LOGO-UNIDANFE.png';
import DANFEView from '../../../assets/img/products/produtos/LOGO-DANFEVIEW.png';
import AutoPecas from '../../../assets/img/products/produtos/LOGO-AUTOPECAS.png';
import Ferragens from '../../../assets/img/products/produtos/LOGO-FERRAGENS.png';
import Eletricos from '../../../assets/img/products/produtos/LOGO-ELETRICOS.png';
import Construcao from '../../../assets/img/products/produtos/LOGO-CONSTRUCAO.png';
import Agro from '../../../assets/img/products/produtos/LOGO-UNICOAGRO.png';
import Uninfe from '../../../assets/img/products/produtos/LOGO-UNINFE.png';
import MIA from '../../../assets/img/products/produtos/LOGO-MIA.png';
import EBANK from '../../../assets/img/products/produtos/LOGO-EBANK.png';
import Certificado from '../../../assets/img/products/produtos/LOGO-CERTIFICADO.png';
import UMessenger from '../../../assets/img/products/produtos/LOGO-U-MESSENGER.png';

class ProdutosPage2 extends React.Component<any, any> {
	private ProdutoService: ProdutoService;
	private ClienteService: ClienteService;
	private PedidoService: PedidoService;

	private NotificationUtil: NotificationUtil;
	private ModalUltil: ModalUltil;
	private CookieUtil: CookieUtil;

	static defaultProps = {
		Produto: undefined,
	};

	state = {
		preco: "Carregando...",
		PedidoId: undefined,
		loading: false,
		redirect: false,
		redirectPage: undefined,
	};

	constructor(props: any) {
		super(props);

		this.ProdutoService = new ProdutoService();
		this.ClienteService = new ClienteService();
		this.PedidoService = new PedidoService();

		this.NotificationUtil = new NotificationUtil();
		this.ModalUltil = new ModalUltil(props);
		this.CookieUtil = new CookieUtil();

		this.setPrice = this.setPrice.bind(this);
		this.comprar = this.comprar.bind(this);
	}

	componentDidMount() {
		this.setState({
			img: this.props.img,
			colorHex: this.props.colorHex,
			routePath: this.props.routePath,
			content: this.props.content,
			title: this.props.title,
			buttonText: this.props.buttonText,
			buttonRoute: this.props.buttonRoute,
		});
	}

	async setPrice() {
		this.ProdutoService.GetPreco(this.props.Produto.ID)
			.then((result) => {
				let response: IResponse = result.data;

				if (response.success) {
					let preco: any = response.data;

					preco = preco.preco ? preco.preco : "Preço não encontrado. recarregue a página novamente";

					let label = this.props.Produto.Td1Text;
					let newText = label.replace("#preco#", preco);

					this.setState({
						preco: newText,
					});
				}
			})
			.catch((err) => {
				this.NotificationUtil.Exception(err);
			});
	}

	async comprar(ID: any) {
		try {
			let cliente = this.ClienteService.GetClienteLogado();
			this.setState({ loading: true });

			let renovacao = localStorage.getItem('RenovacaoID');

			if (renovacao) {
				throw new Error("Não é possível realizar a compra de produtos durante uma renovação");
			}

			this.PedidoService.Comprar(ID, 1, cliente ? cliente.idcliente : null)
				.then((result) => {
					let response: IResponse = result.data;

					//TODO: Verificar sobre o segundo pedido
					if (response.success) {
						let id = response.data.IDPedido;

						this.setState({
							PedidoId: id,
						});

						this.CookieUtil.Set("OrderID", id);
						localStorage.setItem("OrderID", id);

						updateQuantity(1);

						this.ModalUltil.SuccessCustomTitle("Produto adicionado ao carrinho!", "Você está a poucos passos de adquirir uma solução de sucesso!");
					} else {
						this.ModalUltil.Exception(
							response.message ? response.message : "Houve um erro ao adicionar o produto"
						);
					}

					this.setState({ loading: false });
				})
				.catch((err) => {
					this.setState({ loading: false });
					this.ModalUltil.Exception(err);
				});
		} catch (err) {
			this.setState({ loading: false });
			this.ModalUltil.Exception(err);
		}
	}
	
	render() {
		return (
			<div>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Ferramentas para gestão integrada | Unimake Software</title>
					<link rel="canonical" href={`${process.env.PUBLIC_URL}/produtos`} />
					<meta name="keywords" content="Unimake, Software, Unico, ERP, UniDANFE, DANFE View, UniNFe, Consult-e, UniNFSe, UniCTe, UniTransfer, Nota, Fiscal, Serviço, Eletronica, Conhecimento, Transporte, Eletronico" />
					<meta name="description" content="A página de produtos da Unimake Software possui softwares de geração, integrados, gerenciador de arquivos e muito mais. Confira as possibilidades!" />
				</Helmet>

				<div className="produtos-container">
					<div className="produtos-title">
						<h1>Encontre a Solução Certa Para Seu Negócio</h1>
					</div>

					<div className="produtos-grid">
						<div className="grid1">
							<div className="gemini">
								<img src={Gemini} alt="Gemini" />
								<div className="details">
									<h3>Gemini</h3>
									<p>Serviço de monitoramento de backup na nuvem.</p>
									<button><a href="https://inside.unimake.com.br/geminibackup">Saiba mais</a></button>
								</div>
							</div>

							<div className="unidanfe">
								<img src={UniDANFE} alt="UniDANFE" />
								<div className="details">
									<h3>UniDANFE</h3>
									<p>Componente de integração, impressão e envio de documentos fiscais.</p>
									<button><a href="https://inside.unimake.com.br/unidanfe">Saiba mais</a></button>
									<button onClick={() => {this.comprar(3);}}>
										Comprar
									</button>
								</div>
							</div>
						</div>

						<div className="grid2">
							<div className="danfeview">
								<img src={DANFEView} alt="DANFE View" />
								<div className="details">
									<h3>DANFE View Plus</h3>
									<p>Gerenciador de documentos XML emitidos contra o CNPJ de sua empresa, licença para um único CNPJ.</p>
									<button><a href="https://inside.unimake.com.br/danfe-view">Saiba mais</a></button>
									<button onClick={() => {this.comprar(2);}}>
										Comprar
									</button>
								</div>
							</div>

							<div className="danfeview">
								<img src={DANFEView} alt="DANFE View" />
								<div className="details">
									<h3>DANFE View OFFICE</h3>
									<p>Gerenciador de documentos XML emitidos contra o CNPJ de sua empresa, licença para vários CNPJ's.</p>
									<button><a href="https://inside.unimake.com.br/danfe-view">Saiba mais</a></button>
									<button onClick={() => {this.comprar(14);}}>
										Comprar
									</button>
								</div>
							</div>
						</div>

						<div className="grid3">
							<div className="unico">
								<img src={AutoPecas} alt="Unico - Auto Peças" />
								<div className="details">
									<h3>Auto Peças</h3>
									<p>A melhor solução para sua auto peças.
										<br/>
										<br/>
									</p>
									<button><a href="https://inside.unimake.com.br/unico-auto">Saiba mais</a></button>
								</div>
							</div>

							<div className="unico">
								<img src={Ferragens} alt="Unico - Ferragens" />
								<div className="details">
									<h3>Ferragens</h3>
									<p>Solução de gestão exclusiva para lojas de ferragens.</p>
									<button><a href="https://inside.unimake.com.br/unico-ferragens">Saiba mais</a></button>
								</div>
							</div>
						</div>

						<div className="grid4">
							<div className="unico">
								<img src={Eletricos} alt="Unico - Materiais Elétricos" />
								<div className="details">
									<h3>Materiais Elétricos</h3>
									<p>Solução de gestão exclusiva para lojas de materiais elétricos</p>
									<button><a href="https://inside.unimake.com.br/unico-material-eletrico">Saiba mais</a></button>
								</div>
							</div>

							<div className="unico">
								<img src={Construcao} alt="Unico - Materiais de Construção" />
								<div className="details">
									<h3>Materiais de Construção</h3>
									<p>Solução de gestão exclusiva para materiais de construção</p>
									<button><a href="https://inside.unimake.com.br/unico-material-construcao">Saiba mais</a></button>
								</div>
							</div>
						</div>

						<div className="grid5">
							<div className="unico">
								<img src={Agro} alt="Unico - Agro" />
								<div className="details">
									<h3>Unico AGRO</h3>
									<p>Sistema de gestão exclusiva para agroindustrias.</p>
									<button><a href="https://inside.unimake.com.br/unico-fecularia">Saiba mais</a></button>
								</div>
							</div>

							<div className="uninfe">
								<img src={Uninfe} alt="UniNFe" />
								<div className="details">
									<h3>UniNFe</h3>
									<p>Componente open source para envio de XML's fiscais diversos.</p>
									<button><a href="https://inside.unimake.com.br/uninfe">Saiba mais</a></button>
								</div>
							</div>
						</div>

						<div className="grid6">
							<div className="mia">
								<img src={MIA} alt="MIA" />
								<div className="details">
									<h3>MIA ERP</h3>
									<p>Software de gestão empresarial para simplificar operações.</p>
									<button><a href="https://inside.unimake.com.br/mia">Saiba mais</a></button>
								</div>
							</div>

							<div className="umessenger">
								<img src={UMessenger} alt="U-Messenger" />
								<div className="details">
									<h3>u-Messenger</h3>
									<p>API para automatização de comunicação entre sistemas com o WhatsApp.</p>
									<button><a href="https://inside.unimake.com.br/u-messenger">Saiba mais</a></button>
								</div>
							</div>
						</div>

						<div className="grid7">
							<div className="ebank">
								<img src={EBANK} alt="E-Bank" />
								<div className="details">
									<h3>e-Bank</h3>
									<p>Plataforma de integração bancária para sistemas ERP.</p>
									<button><a href="https://inside.unimake.com.br/e-bank">Saiba mais</a></button>
								</div>
							</div>

							<div className="certificado">
								<img src={Certificado} alt="Certificado Digital" />
								<div className="details">
									<h3>Certificado Digital A1</h3>
									<p>Certificado digital para emissão de documentos fiscais.</p>
									<button><a href="https://inside.unimake.com.br/certificados">Saiba mais</a></button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProdutosPage2;