import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import StorageService from "../../services/StorageService";
import Store from "../../store/Store";
import { HashLink } from "react-router-hash-link";

class UmkLeftNav extends React.Component<any, any> {
	private Storage: StorageService;

	static defaultProps = {
		Mode: "horizontal",
		visible: false,
	};

	state = {
		Logged: false,
	};

	constructor(props: any) {
		super(props);

		this.loadUser = this.loadUser.bind(this);

		this.Storage = new StorageService();
	}

	componentDidMount() {
		Store.on("clientNameUpdated", this.loadUser);

		this.loadUser();
	}

	componentWillUnmount() {
		Store.removeListener("clientNameUpdated", this.loadUser);
	}

	loadUser() {
		let user = this.Storage.Get("Usuario");

		if (user) {
			this.setState({
				Logged: true,
			});
		}
	}

	onClose = () => {
		this.props.onCloseDrawer();
	};

	render() {
		return (
			<Menu className="menu-unimake" mode={this.props.Mode} defaultSelectedKeys={["home"]}>
				<Link to="/">
					<img
						className="logo-image hidden-xs hidden-sm hidden-md"
						src={process.env.NODE_ENV === 'production' ? require("../../assets/img/unimakelogo.png") : ""}
						alt="logo unimake"
					/>
				</Link>
				<Menu.Item key="home" onClick={this.onClose}>
					<Link to="/">Home</Link>
				</Menu.Item>

				<Menu.SubMenu key="produtos" title="Produtos">
					<Menu.Item onClick={this.onClose}>
						<Link to="/produtos">Todos</Link>
					</Menu.Item>
					<Menu.SubMenu key="ERP" title="Sistemas para Gestão ERP">
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unico-auto">
								Auto-Peças
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unico-material-eletrico">
								Materiais Elétricos
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unico-material-construcao">
								Materiais de Construção
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unico-ferragens">
								Ferragens
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unico-fecularia">
								Agro
							</a>
						</Menu.Item>
					</Menu.SubMenu>

					<Menu.SubMenu key="transmissor" title="Transmissor de XML">
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/uninfe">
								UniNFe
							</a>
						</Menu.Item>
					</Menu.SubMenu>

					<Menu.SubMenu key="DFES" title="Gestão de Documentos Fiscais">
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/unidanfe">
								UniDANFE
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/danfe-view">
								DANFE View
							</a>
						</Menu.Item>
					</Menu.SubMenu>

					<Menu.SubMenu key="backup" title="Backup">
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/geminibackup">
								Gemini Backup
							</a>
						</Menu.Item>
					</Menu.SubMenu>
					
					<Menu.SubMenu key="outros" title="Outros">
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/certificados">
								Certificado Digital A1
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/u-messenger">
								u-Messenger
							</a>
						</Menu.Item>
						<Menu.Item onClick={this.onClose}>
							<a rel="noopener noreferrer" href="https://inside.unimake.com.br/e-bank">
								e-Bank
							</a>
						</Menu.Item>
					</Menu.SubMenu>
				</Menu.SubMenu>

				<Menu.Item key="downloads" onClick={this.onClose}>
					<Link to="/downloads">Downloads</Link>
				</Menu.Item>

				<Menu.SubMenu key="unimake" title="A Unimake">
					<Menu.Item key="sobre" onClick={this.onClose}>
						<HashLink smooth to="/#sobre">Sobre nós</HashLink>
					</Menu.Item>

					<Menu.Item key="trabalhe" onClick={this.onClose}>
						<Link to="/trabalhe-conosco">Trabalhe Conosco</Link>
					</Menu.Item>

					<Menu.Item key="lgpd" onClick={this.onClose}>
						<Link to="/lgpd">Política de Privacidade - LGPD</Link>
					</Menu.Item>

					<Menu.Item key="contato" onClick={this.onClose}>
						<a rel="noopener noreferrer" href="https://unimake.movidesk.com/kb/pt-br">
							Contato
						</a>
					</Menu.Item>
				</Menu.SubMenu>

				<Menu.Item key="blog" onClick={this.onClose}>
					<a rel="noopener noreferrer" href="https://blog.unimake.com.br/">
						Blog
					</a>
				</Menu.Item>

				<Menu.Item hidden={this.state.Logged == false} key="area-cliente" onClick={this.onClose}>
					<Link to="/cliente/dashboard">Área do Cliente</Link>
				</Menu.Item>
			</Menu>
		);
	}
}

export default UmkLeftNav;