import React from "react";
import { Collapse, Empty, Button, Modal, Table } from "antd";
import DownloadService from "../../services/DownloadService";
import IResponse from "../../contract/IResponse";
import ReactDataGrid from "react-data-grid";
import LoadingOverlay from "react-loading-overlay";
import { format } from "date-fns";
import CommonUtil from "./../../utils/CommonUtil";
import NotificationUtil from "./../../utils/NotificationUtil";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";

const Panel = Collapse.Panel;

class UmkDownload extends React.Component<any, any> {
	private NotificationService: NotificationUtil;
	private DownloadService: DownloadService;
	private Common: CommonUtil;

	DateColumnFormatter = (value) => {
		return <span> {this.Common.formatDateSimple(value, "dd/MM/yyyy HH:mm")} </span>;
	};

	TextFormatter = (value) => {
		return <p style={{ whiteSpace: "normal", textAlign: "left", fontWeight: 550, color: "black" }}> {value} </p>;
	};

	ButtonDownloadFormatter = (value) => {
		return (
			<Button
				style={{ height: "95%", width: "98%", padding: "4px 0px", }}
				block
				shape="round"
				type="primary"
				icon={<DownloadOutlined />}
				onClick={() => {
					this.handleOpenModal(value);
				}}>
				Download
			</Button>
		);
	};

	private ButtonCloseModal = (
		<Button
			style={{ background: "#034563", color: "#fff" }}
			type="ghost"
			icon={<CloseOutlined />}
			onClick={() => {
				this.handleCancel();
			}}>
			{" "}
			Fechar{" "}
		</Button>
	);

	defaultProps = {
		Categoria: "",
		Titulo: "",
		Key: "",
		ActiveKey: "0",		
		documentacao: undefined,
		historicoVersao: undefined
	};

	state = {
		datasource: [],
		loading: true,
		modalVisible: false,
		modalSVNVisible: false,
		downloadData: {
			id_nfe_download: undefined,
			link_index: undefined,
			arquivo: undefined,
			descricao: undefined,
			apresentar_eula: undefined,
			link_externo: undefined,
		},
		serversData: [],
	};

	private columns = [
		{
			title: "Descrição",
			key: "descricao",
			dataIndex: "descricao",
			render: this.TextFormatter,
			ellipsis: true,
		},
		{
			title: "Versão",
			key: "versao",
			dataIndex: "versao",			
			width: 110,
			render: (text) => (
				<div
					style={{
						whiteSpace: "normal",
						wordBreak: "break-word",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{text}
				</div>
			),
		},
		{
			title: "Atualização",
			key: "atualizacao",
			dataIndex: "atualizacao",
			render: this.DateColumnFormatter,
			width: 150,
			ellipsis: false,
		},
		{
			title: "Downloads",
			key: "downloads",
			dataIndex: "downloads",
			width: 90,
			render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
		},
		{
			title: "Download",
			key: "download_link",
			dataIndex: "download_link",
			render: this.ButtonDownloadFormatter,
			width: 140,
			ellipsis: false,
		},
	];

	constructor(props: any) {
		super(props);

		this.NotificationService = new NotificationUtil();
		this.DownloadService = new DownloadService();
		this.Common = new CommonUtil();

		this.handleGetDownloads = this.handleGetDownloads.bind(this);
		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleGetDownload = this.handleGetDownload.bind(this);
		this.handleGetServers = this.handleGetServers.bind(this);
	}

	componentDidMount() {
		this.handleGetDownloads();
	}

	handleOpenModal(id) {
		if (this.Common.IsLink(id)) {
			let entity = this.state.datasource.find((x) => x.link_index == id);
			this.UpdateCounter(entity);
			window.open(id);
		} else if (id == "download_svn.php") {
			let entity = this.state.datasource.find((x) => x.link_index == id);
			this.UpdateCounter(entity);

			this.setState({ modalSVNVisible: true });
		} else {
			this.handleGetDownload(id);
		}
	}

	async UpdateCounter(entity) {
		let result = await this.DownloadService.GetDownload(entity.id);
		let response: IResponse = result.data;

		if (response.success) {
			let result: any = response.data[0];

			await this.DownloadService.UpdateCounter(result.id_nfe_download);
			this.handleGetDownloads();
		} else {
			this.NotificationService.Exception(response.message);
		}
	}

	handleCancel() {
		this.setState({
			modalVisible: false,
			modalSVNVisible: false,
		});
	}

	async handleGetDownloads() {
		this.setState({ loading: true });

		let result = await this.DownloadService.ProdutoDownload(this.props.Categoria);

		this.setState({ loading: false });

		let response: IResponse = result.data;

		let resolved = this.resolveEntities(response.data);

		this.setState({
			datasource: resolved,
		});

		this.setState({ loading: false });
	}

	async handleGetDownload(id: any) {
		let result = await this.DownloadService.GetDownload(id);
		let response: IResponse = result.data;

		if (response.success) {
			this.setState({
				downloadData: response.data[0],
			});

			this.handleGetServers();
		} else {
			this.NotificationService.Exception(response.message);
		}
	}

	async handleGetServers() {
		let result = await this.DownloadService.GetServers();
		let response: IResponse = result.data;

		if (response.success) {
			this.setState({
				serversData: response.data,
				modalVisible: true,
			});
		} else {
			this.NotificationService.Exception(response.message);
		}
	}

	async handleDownload(server) {
		let result = await this.DownloadService.FinalDownload(server.idserverdown);
		let response: IResponse = result.data;

		if (response.success) {
			let http = response.data[0].enderecohttp;
			window.open(`${http}/${this.state.downloadData.arquivo}`);
			await this.DownloadService.UpdateCounter(this.state.downloadData.id_nfe_download);
			this.handleGetDownloads();

			this.handleCancel();
		} else {
			this.NotificationService.Exception(response.message);
		}
	}

	resolveEntities(Data: Array<any>) {
		Data.forEach((item) => {
			item.downloads = item.downloads + item.downloads2;

			if (item.link_index) {
				item.download_link = item.link_index;
			} else {
				item.download_link = item.id;
			}
		});

		return Data;
	}

	render() {
		const totalWidth = this.columns.reduce((sum, col) => sum + (col.width || 200), 0);

		return (
			<>
				<div id={this.props.Categoria} style={{ marginTop: 10, marginBottom: 10 }}>
					<Collapse defaultActiveKey={[this.props.ActiveKey]} accordion>
						<Panel header={this.props.Titulo} key={this.props.Key}>
							<LoadingOverlay active={this.state.loading} spinner text="Carregando dados...">
								{this.state.datasource.length > 0 ? (
									<div>
										<div style={{ float: 'right', marginBottom: 10 }}>
											<a style={{ margin: 5, display: this.props.documentacao ? 'inline' : 'none' }} target="_blank" rel="noopener noreferrer" href={this.props.documentacao}>
												Documentação
											</a>

											<a style={{ margin: 5, display: this.props.historicoVersao ? 'inline' : 'none' }} target="_blank" rel="noopener noreferrer" href={this.props.historicoVersao}>
												Histórico de versão
											</a>
										</div>
										
										<div>
											{
												(this.state.datasource.length > 0) ? (
													<Table bordered={true} dataSource={this.state.datasource} columns={this.columns} pagination={false} scroll={{ x: totalWidth }} />
												) : (
													<Empty description="Nenhum registro encontrado" />
												)
											}
										</div>

									</div>

								) : (
									<Empty description="Nenhum registro encontrado" />
								)}
							</LoadingOverlay>
						</Panel>
					</Collapse>
				</div>

				<Modal
					title="Download"
					visible={this.state.modalVisible}
					onCancel={this.handleCancel}
					cancelText="Fechar"
					footer={this.ButtonCloseModal}
					centered
					width={700}>
					<h3>{this.state.downloadData.descricao}</h3>
					<h3>Selecione um servidor para fazer o download: </h3>

					<p>
						{this.state.serversData.map((item, index) => (
							<Button
								onClick={() => {
									this.handleDownload(item);
								}}
								type="primary"
								icon={<DownloadOutlined />}
								style={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}>
								{" "}
								<span> Download Servidor </span> <span> - {index + 1} </span>
							</Button>
						))}
					</p>
				</Modal>

				<Modal
					title="Código Fonte"
					visible={this.state.modalSVNVisible}
					onCancel={this.handleCancel}
					cancelText="Fechar"
					footer={this.ButtonCloseModal}
					centered
					width={700}>
					<p style={{ textAlign: "justify", lineHeight: 1.7 }}>
						<h3>Para baixar os fontes do UniNFe e UniCTe siga as seguintes instruções:</h3>
						<span style={{ color: "grey" }}>01) Acesse o site </span>{" "}
						<a target="_blank" rel="noopener noreferrer" href="https://sourceforge.net/">
							www.sourceforge.com
						</a>{" "}
						<br />
						<span style={{ color: "grey" }}>
							02) Crie uma conta de usuário neste site para sua utilização em "Create Account".
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							03) Baixe o aplicativo TortoiseSVN para Windows em um site de downloads qualquer.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							04) Instale o aplicativo TortoiseSVN em seu equipamento (após encerrar a instalação reinicie
							o computador). Este software vai ser o responsável por baixar os fontes e mantê-los
							atualizados sempre que você desejar.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							05) Crie uma pasta no HD do seu computador para receber os fontes dos aplicativos.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							06) Clique com o botão direito do mouse sobre a pasta criada e selecione a opção SVN
							CHECKOUT no menu suspenso que será apresentado.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							07) Na tela que será apresentada, na opção "URL of repository", informe o seguinte endereço:{" "}
						</span>{" "}
						<a target="_blank" href="http://svn.code.sf.net/p/uninfe/code/">
							http://svn.code.sf.net/p/uninfe/code/
						</a>{" "}
						<br />
						<span style={{ color: "grey" }}>
							08) Na opção "Checkout directory" da mesma tela deve ser informado a pasta que você criou,
							normalmente o aplicativo já traz a pasta sugerindo automanticamente.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							09) Pressione o botão OK. Logo em seguida vai aparecer uma tela solicitando seu Login e
							Senha. Informe os dados que você cadastrou no site sourceforge.com. Pressione OK.
						</span>{" "}
						<br />
						<span style={{ color: "grey" }}>
							10) Agora basta clicar novamente na pasta criada com o botão direito do mouse e selecionar a
							opção "SVN UPDATE" para baixar os fontes. Sempre que desejar atualizar novamente os fontes
							utilize esta opção.
						</span>{" "}
						<br /> <br />
						<span style={{ color: "grey" }}>
							Pronto! Com isso os fontes dos aplicativos serão baixados e você terá uma forma muito
							prática de mantê-los atualizados sempre que desejar ou novas atualizações forem liberadas.
						</span>
					</p>
				</Modal>
			</>
		);
	}
}
export default UmkDownload;
